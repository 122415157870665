// Modal toggle
// ***************
$(document).ready(function() {
    $('body').on('click', '.overlay-toggle', function() {

      // Set a hash on the url
      window.location.hash = "modal";

        var modalID = $(this).attr('href');
        $(modalID).toggleClass('on');

        if (modalID = 'undefined') {

          var modalID = $(this).data("product");
          var modalID = '#'+ modalID +'';

          $(modalID).toggleClass('on');

        }

      return false;

    });

    $(window).on('hashchange', function (event) {
        if(window.location.hash != "#modal") {
            $('.modal').removeClass('on');
        }
    });
});
