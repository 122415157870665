// Decode the URL string passed into the App after a native QR Code Scan
// ***************
function getParameterByName(name, url) {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

// Today's date, in a nice/universal format (DD MMM YYYY)
// ***************
function todaysDate(){
    return new Date().toLocaleDateString('en-GB', {
        day : 'numeric',
        month : 'short',
        year : 'numeric'
    }).split(' ').join(' ');
}

// Populate the Product information in the modal overlays
// ***************
function populateProductInfo(product) {

  // console.log(product);

  $('#'+ product.id +' .product__name').html(product.name);
  $('#'+ product.id +' .product__description').html(product.description);
  $('#'+ product.id +' .product__brand .list-value').html(product.brand);
  $('#'+ product.id +' .product__identifiers .list-value').html(product.identifiers.GTIN);
  $('#'+ product.id +' .product__batch .list-value').html(product.customFields.batch);
  $('#'+ product.id +' .product__date-manufactured .list-value').html(product.customFields.manufactured);
  $('#'+ product.id +' .product__date-purchased .list-value').html(product.customFields.purchased);
  $('#'+ product.id +' .product__date-shipped .list-value').html(product.customFields.shipped);
  $('#'+ product.id +' .product__higg-env .list-value').html(product.customFields.higg_env);
  $('#'+ product.id +' .product__higg-soc .list-value').html(product.customFields.higg_soc);
  $('#'+ product.id +' .product__manu .list-value').html(product.customFields.manufacturer);
  $('#'+ product.id +' .product__size .list-value').html(product.customFields.size);
  $('#'+ product.id +' .product__color .list-value').html(product.customFields.color);

}

// Lock the button and input once entered competition
// ***************
function lockAfterComplete(){
  $('#updateFirstName .button').html('Thanks for Entering');
  $('#updateFirstName .button, #updateFirstName input').attr("disabled", true);
}

// Add an action to a THNG
// ***************
function addAction(thng, action) {
  return thng.action(action).create({
    params: { project: app.project },
  });
}

// Switch the UI to remove scan button and show name input/submit
// ***************
function winnerUiSwitch() {
  $('.user, .scanBttn').addClass('completed');
}
