function readThng(user) {

  var winningMessage = "All the artists have been collected!";

    // Read thng, then product to get the product's ID and update image gallery
    // ***************
    user.thng(thngId).read().then(function(thng) {

      var userFirstName = user.firstName;

      user.product(thng.product).read().then(function(product) {

        // if got product give notification and add _existingArtistScanned action
        const scannedProducts = user.tags || [];
        const productId = product.id;

        if(scannedProducts.includes(productId)) {

          // Have Product
          addAction(thng, "_ExistingArtistScanned");

          // Count number of items collected
          var numberItems = scannedProducts.length;

          // Check if they have completed the wardrobe
          if(numberItems > 3) {

            winnerUiSwitch();

            if(userFirstName.match("^anon-")) {
              toaster(winningMessage);
            } else {
              lockAfterComplete();
            }

          // If not - just remind them they have scanned product already!
          } else {
            toaster('You already have this artist');
          }

        } else {

          // Add Product to Array
          scannedProducts.push(productId);

          // Count number of items collected
          var numberItems = scannedProducts.length;

          // Add actions
          addAction(thng, "_NewArtistScanned");
          addAction(thng, "_ArtistAdded");

          // Show in UI
          $("div[data-product='" + product.id +"']").addClass('owned');
          $("#artist-link_" + product.id).removeClass('lockedLink');
          $("#artist-link_" + product.id).off('click');

          // Save Tag to user
          var update = {
            tags: scannedProducts
          }

          user.update(update).then(function(){

            // If not collected everything
            if(numberItems < 4) {

              toaster('Added a new artist to your collection');

            // If you've got everything!
            } else {

              addAction(thng, "_AllArtistsScanned");
              winnerUiSwitch();
              toaster(winningMessage);

            }

          });

        }

      })

    }, function(error){

      // Add a Failed Scan action if the thng isn't recognised
      var actions = {
        type: '_ScanFailed'
      };
      user.action('all').create(actions);

      toaster('Couldn\'t Locate that Product', 'error');

    });

}
